import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { OrganisationsService }  from '../../services/organisations.service';
import { SectionsService }  from '../../services/sections.service';
import { UsersService }  from '../../services/users.service';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService }  from '../../services/core/config.service';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-schools-form',
  templateUrl: './schools-form.component.html',
  styleUrls: ['./schools-form.component.scss']
})
export class SchoolsFormComponent implements OnInit {
	error : boolean = false;
	item : any = {};
	errors : any = [];
	mutateForm;
	errormessage = false;
	successmessage = false;
	sections : any = [];
	gdprUploadFile: any = false;
	gdprUploadFileInprogress: boolean = false;
	tabs : any = {'info': true};
	teachers: any = [];
	students: any = [];
	chapters: any = [];
	frontTypes: any = [];

	sortColumns = {
		'email' : { type: 'string', direction: ''},
		'lastLogin' : { type: 'string', direction: ''},
		'regDate' : { type: 'string', direction: ''},
		'active' : { type: 'numeric', direction: ''}
	};
	
	constructor(
  		public organisationsService: OrganisationsService,
  		public sectionsService: SectionsService,
  		public usersService: UsersService,
  		public collectionManager: CollectionManagerService,
 		private route: ActivatedRoute,
		private modalService: NgbModal,
		private config: ConfigService,
  		private ngxLoader: NgxUiLoaderService,
		private authenticationService: AuthenticationService
	) { }
	
	isAdmin: boolean;

	ngOnInit(): void {
	  this.authenticationService.isCurrentUserAdmin().then((data) => {
		this.isAdmin = data;
	  });
		this.fetchItem();
		this.fetchSections();
		try{
			let key = sessionStorage.getItem('school-tab');
			if (key) {
				this.changeTab(key);
			}
		} catch (e) {
		}
	}
	
	fetchSections () {
		let self = this;
		this.sectionsService.read('sections/all').then((response) => {
			self.sections = response;
		});
	}
	
	fetchItem () {
		let id = this.route.snapshot.paramMap.get('id');

		let self = this;
	    this.ngxLoader.start();
		this.organisationsService.read('organisations/details/'+id+'').then((response) => {
	      self.item = response;
			
		  for (let key in self.item['frontTypeEnum']) {
			  let frontType = self.item['frontTypeEnum'][key];
			  self.frontTypes.push({
				  key: key,
				  value: frontType
			  });
		  }

	      self.ngxLoader.stop();
		});
		this.getTeachers();
		this.getStudents();
		this.getChapters();
	}
	
	getTeachers () {
		let self = this;
		let id = this.route.snapshot.paramMap.get('id');
		this.organisationsService.read('organisations/teachers/'+id+'').then((response) => {
	      self.teachers = response;
		});
	}
	
	getStudents () {
		let self = this;
		let id = this.route.snapshot.paramMap.get('id');
		this.organisationsService.read('organisations/students/'+id+'').then((response) => {
	      self.students = response;
		});
	}
	
	getChapters () {
		let self = this;
		let id = this.route.snapshot.paramMap.get('id');
		this.organisationsService.read('chapters').then((response) => {
	      self.chapters = response;
		  console.dir(self.chapters);
		});
	}
	
	getLicenseCount (chapterId) {
		for (let key in this.item['chapters']) {
			let chapter = this.item['chapters'][key];
			if (chapter['chapterId'] === chapterId) {
				return chapter['licenseCount'];
			}
		}
		return 0;
	}
	
	updateLicenseCount (event, chapterId) {
		for (let key in this.item['chapters']) {
			let chapter = this.item['chapters'][key];
			if (chapter['chapterId'] === chapterId) {
				this.item['chapters'][key]['licenseCount'] = event.target.value;
				return;
			}
		}
		
		this.item['chapters'].push({
			id: null,
			organisationId: this.item.id,
			chapterId: chapterId,
			licenseCount: event.target.value
		});
		
		console.log(this.item['chapters']);
	}
	
	changeTab (target) {
		for (let key in this.tabs) {
			this.tabs[key] = false;
		}
		this.tabs[target] = true;
		sessionStorage.setItem('school-tab', target);
	}
	
	save() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;
		
		this.organisationsService.save('organisations/save', self.item).then((response) => {
			self.ngxLoader.stop();
			self.item = response;
			self.successmessage = true;
			self.scrollToAlert();
			self.gdprUploadFile = false;
		}).catch (function (response) {
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
			self.scrollToAlert();
		});
	}
	
	scrollToAlert(): void {
		const element = document.querySelector(".alert-message-container");
		if (element) {
			element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
		}
	}
	
	getLicenseValue (chapterId) {
		if (this.item && this.item['chapters']) {
			for (let key in this.item['chapters']) {
				let chapter = this.item['chapters'][key];
				if (chapter['chapterId'] === chapterId) {
					return chapter['licenseCount'];
				}
			}
			return 0;
		}
	}

	delete (user) {
		let self = this;
		this.ngxLoader.start();
		this.usersService.delete('users/delete/'+user.id+'').then((response) => {
			self.ngxLoader.stop();
			self.getTeachers();
			self.getStudents();			
		}).catch (function (error) {
			self.ngxLoader.stop();
		});	
	}
	
	confirmDelete(user) {
		let self = this;
	
		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je '+user.name+' wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				this.delete(user);
			}
		});
	}
	
	sort (subject, collection) {
	 	if(!this.sortColumns.hasOwnProperty(subject))
	 		alert('Sorteer optie bestaat niet');
	 		
	 	for (let key in this.sortColumns) {
	 		if (key !== subject)
		  		this.sortColumns[key].direction = '';
		}
	 	
	 	let column = this.sortColumns[subject];
	 	this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');
	 	
	    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
	}
	
	search (event, collection) {
		let keyword = event.target.value;
		this.collectionManager.search(keyword, collection);
	}
}
