import { Component, OnInit } from '@angular/core';
import { ApiConnectionService } from '../../services/core/api-connection.service';
import { ConfigService } from '../../services/core/config.service';
import { AuthenticationService } from '../../services/core/authentication.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CollectionManagerService } from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UsersService } from '../../services/users.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from '../../modals/modal-content/modal-content.component';

import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent {

  public apiUrl;
  public backupMxUrl;
  public backupWordpressUrl;

  public canShow = false;
  public currentUser: any;

  feedbacks: any = [];

  syncLessonLoading = false;
  syncLessonDone = false;

  syncLessonProgress = 0;
  syncLessonTotal = 0;

  syncPagesLoading = false;
  syncPagesDone = false;

  syncTermsLoading = false;
  syncTermsDone = false;

  VERSION_INFO = null;
  INTRO_IMAGE_URL = null;
  INTRO_TEXT_STUDENT = null;
  INTRO_TEXT_TEACHER = null;
  INTRO_TEXT = null;
  PORTAL_NEWS = null;

  BANNER_STUDENT = null;
  BANNER_TEACHER = null;

  modal;

  youtubeSafeURL = null;

  constructor
    (
      public http: HttpClient,
      public authenticationService: AuthenticationService,
      public configService: ConfigService,
      public router: Router,
      public api: ApiConnectionService,
      public collectionManager: CollectionManagerService,
      private ngxLoader: NgxUiLoaderService,
      private modalService: NgbModal,
      private usersService: UsersService,
      private sanitizer: DomSanitizer
    ) {
    let self = this;

    self.authenticationService.getCurrentUser();
    self.authenticationService.data.subscribe(data => {
      if (Object.keys(data).length) {
        self.currentUser = data;
      } else {
        self.currentUser = false;
      }
    });

    self.authenticationService.authState.subscribe(data => {
      if (data === false) {
        self.router.navigate(['login']);
      }
    });
  }

  async getData(url = "") {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    return response.text(); // parses JSON response into native JavaScript objects
  }

  async ngOnInit(): Promise<void> {
    let self = this;

    this.apiUrl = this.configService.get('api_url');
    this.backupMxUrl = this.configService.get('api_url') + "tools/backup";
    this.backupWordpressUrl = this.configService.get('api_url') + "tools/backupWordPress";

    if (!(window.location.host).includes("localhost")) {
      this.VERSION_INFO = await this.getData("https://" + (window.location.host) + "/version.txt");
    }

    this.INTRO_IMAGE_URL = this.configService.get('intro_image_url');
    this.INTRO_TEXT_STUDENT = this.configService.get('intro_text_student');
    this.INTRO_TEXT_TEACHER = this.configService.get('intro_text_teacher');
    this.INTRO_TEXT = this.configService.get('intro_text');
    this.PORTAL_NEWS = this.configService.get('portal_news');

    this.BANNER_STUDENT = this.configService.get('banner_student');
    this.BANNER_TEACHER = this.configService.get('banner_teacher');

    this.all();
    self.getSyncStatus();
    setInterval(function () {
      self.getSyncStatus();
    }, 10000);
  }

  getSyncStatus() {
    let self = this;
    this.api.read('lessons/syncStats',).then((response) => {
      self.syncLessonProgress = (response['total'] - response['toSync']);
      self.syncLessonTotal = response['total'];

      if (response['toSync'] == 0) {
        self.syncLessonLoading = false;
        self.syncLessonDone = true;
      } else {
        self.syncLessonLoading = true;
        self.syncLessonDone = false;
      }

      if (self.syncPagesLoading == false) {
        self.syncPagesDone = true;
      }

      if (self.syncTermsLoading == false) {
        self.syncTermsDone = true;
      }
    });
  }

  syncLessons() {
    let self = this;

    this.modal = this.modalService.open(ModalContentComponent, {
      backdrop: 'static',
      keyboard: false
    });

    this.api.read('lessons/sync',).then((response) => {
      self.modal.componentInstance.message = '<i class="fas fa-check" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Jobs aangemaakt!';
    });

    this.modal.componentInstance.message = '<i class="fas fa-circle-notch fa-spin" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Sync jobs worden aangemaakt';
    this.modal.componentInstance.title = 'Sync lessen';
    this.modal.result.then((result) => {
    });
  }

  syncPages() {
    let self = this;

    this.modal = this.modalService.open(ModalContentComponent, {
      backdrop: 'static',
      keyboard: false
    });

    this.syncPagesDone = false;
    this.syncPagesLoading = true;
    this.api.read('pages/sync',).then((response) => {
      self.modal.componentInstance.message = '<i class="fas fa-check" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Pagina\'s gesynchroniseerd!';

      self.syncPagesDone = true;
      self.syncPagesLoading = false;
    });

    this.modal.componentInstance.message = '<i class="fas fa-circle-notch fa-spin" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Pagina\'s aan het synchroniseren';
    this.modal.componentInstance.title = 'Sync pagina\'s';
    this.modal.result.then((result) => {
    });
  }

  syncTerms() {
    let self = this;

    this.modal = this.modalService.open(ModalContentComponent, {
      backdrop: 'static',
      keyboard: false
    });

    self.syncTermsLoading = true;
    self.syncTermsDone = false;

    this.api.read('terms/sync',).then((response) => {
      self.modal.componentInstance.message = '<i class="fas fa-check" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Termen gesynchroniseerd!';

      self.syncTermsDone = true;
      self.syncTermsLoading = false;
    });

    this.modal.componentInstance.message = '<i class="fas fa-circle-notch fa-spin" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Termen aan het synchroniseren';
    this.modal.componentInstance.title = 'Sync termen';
    this.modal.result.then((result) => {
    });
  }

  all() {
    let self = this;

    self.authenticationService.data.subscribe(data => {
      if (Object.keys(data).length != 0) {
        self.canShow = true;
      } else {
        self.canShow = false;
      }
    });

    this.ngxLoader.start();
    this.api.read('lessonFeedbacks/all').then((response) => {
      self.feedbacks = response;
      self.ngxLoader.stop();
    });

    this.api.read('lessonFeedbacks/readAll').then((response) => {
      this.usersService.getUnreadCount();
    });
  }

  getActiveItems() {
    let items = [];
    for (let i = 0; i < this.feedbacks.length; i++) {
      let feedback = this.feedbacks[i];

      if (feedback['archive'] < 1) {
        items.push(feedback);
      }
    }
    return items;
  }

  visitLessonFeedBack(lessonFeedbackId, chapterId) {
    let self = this;
    this.ngxLoader.start();
    this.api.read('lessonFeedbacks/visit/' + lessonFeedbackId + '/' + chapterId + '').then((response) => {
      window.open(response['link'], "_blank");
    }).catch(function (error) {
      self.ngxLoader.stop();
    });
  }

  embed(url) {
    if(this.youtubeSafeURL == null) {
      this.youtubeSafeURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    return this.youtubeSafeURL;
  }
}
