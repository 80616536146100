import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './pages/index/index.component';

import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';

import { HttpClientModule } from '@angular/common/http';

import { ReactiveFormsModule } from '@angular/forms';
import { SchoolsIndexComponent } from './pages/schools-index/schools-index.component';
import { LoginComponent } from './pages/login/login.component';
import { SchoolsFormComponent } from './pages/schools-form/schools-form.component';
import { AuthGuard } from './services/core/authguard.service';

import { FormsModule } from '@angular/forms';
import { SectionsIndexComponent } from './pages/sections-index/sections-index.component';
import { SectionsFormComponent } from './pages/sections-form/sections-form.component';
import { ModalContentComponent } from './modals/modal-content/modal-content.component';
import { ModalConfirmDeleteComponent } from './modals/modal-confirm-delete/modal-confirm-delete.component';
import { HasErrorPipe } from './pipes/has-error.pipe';
import { ChaptersIndexComponent } from './pages/chapters-index/chapters-index.component';
import { ChaptersFormComponent } from './pages/chapters-form/chapters-form.component';
import { UsersIndexComponent } from './pages/users-index/users-index.component';
import { UsersFormComponent } from './pages/users-form/users-form.component';

import { ConfigService }  from './services/core/config.service';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { LessonsFormComponent } from './pages/lessons-form/lessons-form.component';
import { NotauthorizedComponent } from './pages/notauthorized/notauthorized.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { LessonsIndexComponent } from './pages/lessons-index/lessons-index.component';
import { PagesIndexComponent } from './pages/pages-index/pages-index.component';
import { PagesFormComponent } from './pages/pages-form/pages-form.component';
import { LogsComponent } from './pages/logs/logs.component';
import { BannersComponent } from './pages/banners/banners.component';
import { BannersFormComponent } from './pages/banners-form/banners-form.component';

import { registerLocaleData } from '@angular/common';
import LocaleNl from '@angular/common/locales/nl';
import { CheckQuotesComponent } from './pages/check-quotes/check-quotes.component';
import { CheckImagesComponent } from './pages/check-images/check-images.component';

import {NgxPaginationModule} from 'ngx-pagination';
import { ExaminationsIndexComponent } from './pages/examinations-index/examinations-index.component'; 
import { ExaminationsFormComponent } from './pages/examinations-form/examinations-form.component';
import { SyncComponent } from './pages/sync/sync.component';
import { UsersAnswersComponent } from './pages/users-answers/users-answers.component';
import { ActivityLogComponent } from './pages/activity-log/activity-log.component';
import { ActivityLogDetailsComponent } from './pages/activity-log-details/activity-log-details.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CheckYoutubeComponent } from './pages/check-youtube/check-youtube.component';

registerLocaleData(LocaleNl);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#5c2d2d",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#001e79",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "pulse",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(255,255,255,0.8)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};

export function appInit(configService: ConfigService) {
  return () => configService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    SchoolsIndexComponent,
    LoginComponent,
    SchoolsFormComponent,
    SectionsIndexComponent,
    SectionsFormComponent,
    ModalContentComponent,
    ModalConfirmDeleteComponent,
    HasErrorPipe,
    ChaptersIndexComponent,
    ChaptersFormComponent,
    UsersIndexComponent,
    UsersFormComponent,
    LessonsFormComponent,
    NotauthorizedComponent,
    FeedbackComponent,
    LessonsIndexComponent,
    PagesIndexComponent,
    PagesFormComponent,
    LogsComponent,
    BannersComponent,
    BannersFormComponent,
    CheckQuotesComponent,
    CheckImagesComponent,
    ExaminationsIndexComponent,
    ExaminationsFormComponent,
    SyncComponent,
    UsersAnswersComponent,
    ActivityLogComponent,
    ActivityLogDetailsComponent,
    CheckYoutubeComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    DragDropModule,
    NgxPaginationModule,
    EditorModule
  ],
  providers: [
    AuthGuard,
	ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [ConfigService]
    },
    { provide: LOCALE_ID, 
      useValue: "nl" },
   ],
  bootstrap: [AppComponent],
  entryComponents: [ ModalContentComponent ]
})
export class AppModule { }
