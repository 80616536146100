<ng-container *ngIf="isAdmin">
<div id="head">
	<h1>
		Logboek
	</h1>
	<div>
		<div>
			<label>Start datum: </label>
			<input type="date" [(ngModel)]="startDate" (change)="filterByDate()" placeholder="Begin datum">
		</div>
		<div>
			<label>Eind datum: </label>
			<input type="date" [(ngModel)]="endDate" (change)="filterByDate()" placeholder="Eind datum">
		</div>
	</div>
</div>

<div class="table-search mb-1">
	<input (keyup)="search($event, activitiesOriginal)">
</div>
<table class="table">
	<thead class="thead-dark">
		<tr>
			<th width="10">#</th>
			<th scope="col" class="sortable"
				[ngClass]="{ 'sort-asc': sortColumns['date']['direction'] == 'asc', 'sort-desc': sortColumns['date']['direction'] == 'desc'}"
				(click)="sort('date', activityLogs)">Datum</th>
			<th scope="col" class="sortable"
				[ngClass]="{ 'sort-asc': sortColumns['modifiedUsername']['direction'] == 'asc', 'sort-desc': sortColumns['modifiedUsername']['direction'] == 'desc'}"
				(click)="sort('modifiedUsername', activityLogs)">Gebruiker</th>
			<th scope="col" class="sortable"
				[ngClass]="{ 'sort-asc': sortColumns['responsibleUsername']['direction'] == 'asc', 'sort-desc': sortColumns['responsibleUsername']['direction'] == 'desc'}"
				(click)="sort('responsibleUsername', activityLogs)">Door</th>
			<th scope="col" class="sortable"
				[ngClass]="{ 'sort-asc': sortColumns['type']['direction'] == 'asc', 'sort-desc': sortColumns['type']['direction'] == 'desc'}"
				(click)="sort('type',activityLogs)">Type</th>
			<th scope="col" class="sortable"
				[ngClass]="{ 'sort-asc': sortColumns['description']['direction'] == 'asc', 'sort-desc': sortColumns['description']['direction'] == 'desc'}"
				(click)="sort('description',activityLogs)">Beschrijving</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let activity of activityLogs | paginate: paginationConfig; let i = index"
			[ngClass]="{ 'd-none': activity.hidden == true }">
			<td>{{i + 1}}</td>
			<td>
				{{activity.date}}
			</td>
			<td>
				<a [routerLink]="['/user/form/'+activity.modifiedUserId+'']">{{activity.modifiedUsername}}</a>
			</td>
			<td>
				<a [routerLink]="['/user/form/'+activity.responsibleUserId+'']">{{activity.responsibleUsername}}</a>
			</td>
			<td class="capitalize">
				{{ activity.typeText }}
			</td>
			<td>
				{{activity.changedValuesText}}
			</td>
		</tr>
	</tbody>
</table>

<pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>
</ng-container>

<ng-container *ngIf="!isAdmin">
	<p>Je hebt geen toegang tot deze pagina.</p>
</ng-container>