<h1>
	<span routerLink="/banners" class="btn btn-info mr-2"><i class="fas fa-arrow-left"></i></span>
	<span *ngIf="!item['id']">Banner aanmaken</span>
	<span *ngIf="item.id">Banner wijzigen</span>
</h1>


<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>

<div class="container-fluid d-flex">
	<div class="col-6 p-0 pr-5">

		<div class="d-flex">
			<div class="col-6 p-0 pr-5">
				Banner titel:
				<input type="text" *ngIf="item" class="form-control" id="title" required [(ngModel)]="item.title"
					name="title">
				<div class="error" *ngIf="errors.title">{{errors.title}}</div>
			</div>
			<div class="col-6 p-0">
				Achtergrondkleur:
				<select name="role" [(ngModel)]="item.color" class="form-control">
					<option [selected]="item['color'] == '#7E78B8'" value="#7E78B8">
						Methode paars
					</option>
					<option [selected]="item['color'] == '#F06793'" value="#F06793">
						Methode rood
					</option>
					<option [selected]="item['color'] == '#FCBA63'" value="#FCBA63">
						Methode geel
					</option>
					<option [selected]="item['color'] == '#44C8F5'" value="#44C8F5">
						Methode blauw
					</option>
					<option [selected]="item['color'] == '#51BF9D'" value="#51BF9D">
						Methode groen
					</option>

					<option [selected]="item['color'] == '#f26641'" value="#f26641">
						BX rood
					</option>
					<option [selected]="item['color'] == '#ffc233'" value="#ffc233">
						BX geel
					</option>
					<option [selected]="item['color'] == '#6c8cc7'" value="#6c8cc7">
						BX blauw
					</option>
					<option [selected]="item['color'] == '#59a76d'" value="#59a76d">
						BX groen
					</option>
				</select>
				<div class="error" *ngIf="errors.color">{{errors.color}}</div>
			</div>
		</div>

		<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item.id" name="id">

		<br /><br />

		<div class="d-flex">
			<div class="col-6 p-0 pr-5">
				Startdatum:
				<input type="date" *ngIf="item" class="form-control" id="slug" required [(ngModel)]="item.startAt"
					name="slug">
				<div class="error" *ngIf="errors.startAt">{{errors.startAt}}</div>
			</div>
			<div class="col-6 p-0">
				Einddatum:
				<input type="date" *ngIf="item" class="form-control" id="shortName" required [(ngModel)]="item.endAt"
					name="shortName">
				<div class="error" *ngIf="errors.endAt">{{errors.endAt}}</div>
			</div>
		</div>

		<br /><br />
		<editor [init]="{ menubar: false, plugins: 'link code image table wordcount', toolbar:
		'undo redo | bold italic | \
		bullist numlist | removeformat | code' }" apiKey="hbvs65ybuoaf2bd8jz8j0r5ifdom1y350u5m4a3lotbucw7m"
			[(ngModel)]="item.body"></editor>

		<br /><br />

		<div class="d-flex">
			<div class="col-3 p-0 pr-2">
				Knop tekst:
				<input type="text" *ngIf="item" class="form-control" id="position" required
					[(ngModel)]="item.buttonText" name="position">
				<div class="error" *ngIf="errors.buttonText">{{errors.buttonText}}</div>
				<br /><br />
			</div>
			<div class="col-3 p-0">
				Knop link:
				<input type="text" *ngIf="item" class="form-control" id="position" required [(ngModel)]="item.link"
					name="position">
				<div class="error" *ngIf="errors.link">{{errors.link}}</div>
				<br /><br />
			</div>
			<div class="col-3 p-0 pl-2">
				Knop kleur:
				<select name="role" [(ngModel)]="item.buttonBackgroundColor" class="form-control">
					<option [selected]="item['buttonBackgroundColor'] == '#7E78B8'" value="#7E78B8">
						Methode paars
					</option>
					<option [selected]="item['buttonBackgroundColor'] == '#F06793'" value="#F06793">
						Methode rood
					</option>
					<option [selected]="item['buttonBackgroundColor'] == '#FCBA63'" value="#FCBA63">
						Methode geel
					</option>
					<option [selected]="item['buttonBackgroundColor'] == '#44C8F5'" value="#44C8F5">
						Methode blauw
					</option>
					<option [selected]="item['buttonBackgroundColor'] == '#51BF9D'" value="#51BF9D">
						Methode groen
					</option>

					<option [selected]="item['buttonBackgroundColor'] == '#f26641'" value="#f26641">
						BX rood
					</option>
					<option [selected]="item['buttonBackgroundColor'] == '#ffc233'" value="#ffc233">
						BX geel
					</option>
					<option [selected]="item['buttonBackgroundColor'] == '#6c8cc7'" value="#6c8cc7">
						BX blauw
					</option>
					<option [selected]="item['buttonBackgroundColor'] == '#59a76d'" value="#59a76d">
						BX groen
					</option>
				</select>
				<div class="error" *ngIf="errors.buttonBackgroundColor">{{errors.buttonBackgroundColor}}</div>
				<br /><br />
			</div>
			<div class="col-3 p-0 pl-2">
				Knop tekst kleur:
				<select name="role" [(ngModel)]="item.buttonTextColor" class="form-control">
					<option [selected]="item['buttonTextColor'] == '#000'" value="#000">
						Zwart
					</option>
					<option [selected]="item['buttonTextColor'] == '#FFF'" value="#FFF">
						Wit
					</option>
				</select>
				<div class="error" *ngIf="errors.link">{{errors.buttonTextColor}}</div>
				<br /><br />
			</div>
		</div>

		<div>
			YouTube:
			<input type="text" *ngIf="item" class="form-control" id="position" required (change)="convertYouTube()"
				[(ngModel)]="item.youtube" name="position">
			<div class="error" *ngIf="errors.youtube">{{errors.youtube}}</div>
			<br /><br />
		</div>

		<div class="mb-4 mt-3" [ngClass]="{'disabled': item.youtube != null && item['youtube'].length != 0}">
			Afbeelding:<br />
			<div class="upload-image-container">
				<img [src]="item.imageSource" style="max-width:206px;width:210px;" *ngIf="imageSource == ''" />
				<img [src]="imageSource" style="max-width:206px;width:210px;" *ngIf="imageSource != ''" />
			</div>
			<div class="upload-image-button">
				<input name="image" type="file" id="image-select" accept="image/*"
					(change)="handleImageChange($event)" />
				<button type="button" class="btn btn-sm btn-secondary text-small"
					(click)="triggerImageSelect($event)">Selecteer
					afbeelding</button>
				<button type="button" class="btn btn-sm btn-secondary ml-2 text-small"
					(click)="removeImages()">Verwijder afbeelding</button>
			</div>
		</div>
	</div>
	<div class="col-6 p-0">
		<div class="container-fluid band p-3" [style.backgroundColor]="item.color"
			style="position: sticky; top: 50px; width: 100%;">
			<div class="container px-3">
				<div class="row">
					<div class="col-12 col-md-6 text-center">
						<img *ngIf="item.imageSource && (item.youtube == null || item.youtube.length == 0)" class="img-fluid"
							alt="Methode M ⋆ Methode M ⋆ Dé methode voor burgerschap, maatschappijleer en maatschappijkunde"
							[src]="item.imageSource">

						<iframe style="aspect-ratio: 16 / 9; width: 100%;" *ngIf="item.youtube" type="text/html"
							[src]="embed(item.youtube)" frameborder="0"></iframe>
					</div>
					<div class="col-12 col-md-5 my-5 my-sm-0 d-flex">
						<div class="d-flex align-items-center">
							<div class="d-block">
								<div class="text-white">
									<div class="section-title">
										<h2 [innerHTML]="item.title"></h2>
										<p [innerHTML]="item.body"></p>
									</div>
								</div>
								<p class="mt-3"><a *ngIf="item.buttonText" class="btn btn-success"
										[style.color]="item.buttonTextColor"
										[style.backgroundColor]="item.buttonBackgroundColor" [href]="item.link"
										title="Lees meer over Methode M">{{item.buttonText}}</a></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="form-group row">
	<div class="col-md-2">
		<label class="col-form-label">Actief:</label>
	</div>
	<div class="col-md-10">
		<div class="custom-control custom-switch">
			<input type="checkbox" class="custom-control-input" id="active" name="active"
				[ngModelOptions]="{standalone: true}" [(ngModel)]="item.active">
			<label class="custom-control-label" for="active"></label>
		</div>
	</div>
</div>

<div class="form-group row">
	<div class="col-md-2">
		<label class="col-form-label">Voor studenten:</label>
	</div>
	<div class="col-md-10">
		<div class="custom-control custom-switch">
			<input type="checkbox" class="custom-control-input" id="studentActive" name="studentActive"
				[ngModelOptions]="{standalone: true}" [(ngModel)]="item.studentActive">
			<label class="custom-control-label" for="studentActive"></label>
		</div>
	</div>
</div>

<div class="form-group row">
	<div class="col-md-2">
		<label class="col-form-label">Voor docenten:</label>
	</div>
	<div class="col-md-10">
		<div class="custom-control custom-switch">
			<input type="checkbox" class="custom-control-input" id="teacherActive" name="teacherActive"
				[ngModelOptions]="{standalone: true}" [(ngModel)]="item.teacherActive">
			<label class="custom-control-label" for="teacherActive"></label>
		</div>
	</div>
</div>

<br /><br />
<button (click)="save()" class="btn btn-primary">
	<span *ngIf="!item.id">Toevoegen</span>
	<span *ngIf="item.id">Opslaan</span>
</button>