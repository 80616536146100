export const environment = {
    production: true,
    cdnUrl: 'https://cdn.burgerschapskaarten.it/',
    apiUrl: 'https://api.burgerschapskaarten.it/',
    frontUrl: 'https://www.burgerschapskaarten.it/',
    portalUrl: 'https://portal.burgerschapskaarten.it/',
    systemKey: 'burgerschapskaarten',
    salesWebsite: 'https://over.burgerschapskaarten.nl/',
    contactEmail: 'info@burgerschapskaarten.nl',
    contactPhone: '0031003333333',
    // title: 'Burgerschapskaarten',
    // slogan: 'Burgerschapskaarten',
    // description: 'Kom, we gaan samen aan de slag met maatschappijleer! Methode M is actueel, interactief en vernieuwend.',
    frontStyle: 'method',
    frontModule: 'burgerschap-basisonderwijs'
  };