import { Component, OnInit } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChaptersService } from '../../services/chapters.service';
import { ApiConnectionService } from '../../services/core/api-connection.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { ConfigService } from '../../services/core/config.service';

import { ModalContentComponent } from 'src/app/modals/modal-content/modal-content.component';

@Component({
	selector: 'app-chapters-form',
	templateUrl: './chapters-form.component.html',
	styleUrls: ['./chapters-form.component.scss']
})
export class ChaptersFormComponent implements OnInit {
	error: boolean = false;
	item: any = {};
	errors: any = [];
	studyLevels: any = [];
	errormessage = false;
	lessons: any = [];
	successmessage = false;

	thumbSource: string = '';
	imageSource: string = '';
	coverSource: string = '';

	sections: any = [];
	lessonKeyword: string = '';
	tabs: any = { 'info': true };
	onlyOrphanLessons = true;
	terms: any = [];
	selectedTerms: any = {};
	termKeyword = '';
	termCategory = '';
	termCategories: any = [];

	styles: any = [];
	layouts: any = [];

	exportButton = false;

	modal;

	constructor(
		public apiConnectionService: ApiConnectionService,
		public chaptersService: ChaptersService,
		private route: ActivatedRoute,
		private router: Router,
		private configService: ConfigService,
		private modalService: NgbModal,
		private ngxLoader: NgxUiLoaderService
	) { }

	ngOnInit(): void {
		let self = this;

		this.studyLevels = this.configService.get('study_levels');
		this.studyLevels.reverse();

		this.fetchItem();
		try {
			let key = sessionStorage.getItem('chapter-tab');
			if (key) {
				this.changeTab(key);
			}
		} catch (e) {
		}

		this.apiConnectionService.read('lessons/all').then((response) => {
			self.lessons = response;
		});

		this.setTerms();
		this.setTermCategories();
	}

	convertTextToCSV(text) {
		// Split the text into lines
		const lines = text.split('<br/>');

		// Initialize an empty array to store CSV rows
		const csvRows = [];

		// Iterate over each line
		lines.forEach(line => {
			// Push line into CSV format
			csvRows.push(`"${line}"`);
		});

		// Join CSV rows with newline characters
		const csvContent = csvRows.join('\n');

		return csvContent;
	}


	async exportTerms(event: any) {
		let apiUrl = this.configService.get('api_url')
		const response = await fetch(apiUrl + "terms/list/samenleving/" + event.target.value, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			}
		});

		const res = await response.text();

		const formattedCSV = this.convertTextToCSV(res);

		// Create a blob from the CSV content
		const blob = new Blob([formattedCSV], { type: 'text/csv' });

		// Create a URL for the blob
		const url = window.URL.createObjectURL(blob);

		// Create a link element
		const link = document.createElement('a');
		link.href = url;
		link.download = "export";

		// Append the link to the document body and trigger the download
		document.body.appendChild(link);
		link.click();

		// Cleanup: remove the link and revoke the URL
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	}

	syncLesson(item) {
		let self = this;

		console.log(item)

		this.modal = this.modalService.open(ModalContentComponent, {
			backdrop: 'static',
			keyboard: false
		});

		this.apiConnectionService.read('lessons/syncSingle/' + item['lessonId'] + '',).then((response) => {
			self.modal.componentInstance.message = '<i class="fas fa-check" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Les gesynced!';
		});

		this.modal.componentInstance.message = '<i class="fas fa-circle-notch fa-spin" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Les aan het syncen';
		this.modal.componentInstance.title = 'Sync les';
		this.modal.result.then((result) => {
		});
	}

	fullSync() {
		const self = this;

		const lessons = this.item['lessons'];

		this.modal = this.modalService.open(ModalContentComponent, {
			backdrop: 'static',
			keyboard: false
		});

		let done = 0;
		let total = lessons.length;

		this.modal.componentInstance.message = `<p>Niet wegklikken, anders stopt het sync proces.</p><i class="fas fa-circle-notch fa-spin" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Lessen aan het syncen: ${done}/${total}`;
		this.modal.componentInstance.title = 'Sync lessen';

		for (let i = 0; i < lessons.length; i++) {
			this.apiConnectionService.read('lessons/syncSingle/' + lessons[i].lessonId + '',).then((response) => {
				done++;

				this.modal.componentInstance.message = `<p>Niet wegklikken, anders stopt het sync proces.</p><i class="fas fa-circle-notch fa-spin" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Lessen aan het syncen: ${done}/${total}`;
				if (done == total) {
					self.modal.componentInstance.message = '<p>Niet wegklikken, anders stopt het sync proces.</p><i class="fas fa-check" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Les gesynced!';
				}
			});
		}
	}

	updateTerm(termId, studyLevel, event) {
		let chapterId = this.route.snapshot.paramMap.get('id');
		let keyString = '' + chapterId + '-' + termId + '-' + studyLevel + '';
		if (this.selectedTerms[keyString]) {
			delete this.selectedTerms[keyString]
		} else {
			this.selectedTerms[keyString] = { termId: termId, studyLevel: studyLevel, chapterId: chapterId };
		}
	}

	checkTermChecked(termId, studyLevel) {
		let chapterId = this.route.snapshot.paramMap.get('id');
		let keyString = '' + chapterId + '-' + termId + '-' + studyLevel + '';

		if (this.selectedTerms[keyString]) {
			return true;
		}

		return false;
	}

	setTerms() {
		let chapterId = this.route.snapshot.paramMap.get('id');
		let self = this;
		this.apiConnectionService.read('terms/all').then((response) => {
			self.terms = response;
		});
	}

	setTermCategories() {
		let response2 = [];
		let self = this;
		self.termCategories = [];
		self.termCategories.push({ 'key': '', 'value': 'Alles' });
		this.apiConnectionService.read('terms/categories').then((response) => {
			response = JSON.stringify(response as []);
			response2 = JSON.parse(response as string);
			console.dir(response2);
			for (let x = 0; x < response2.length; x++) {
				self.termCategories.push({ 'key': response2[x], 'value': response2[x] });
			}
		});
	}

	fetchItem() {
		let id = this.route.snapshot.paramMap.get('id');

		let self = this;

		self.styles = [];
		self.layouts = [];

		this.ngxLoader.start();
		this.chaptersService.read('chapters/details/' + id + '').then((response) => {
			self.item = response;
			for (let key in self.item['terms']) {
				let term = self.item['terms'][key];
				let keyString = term['key'];
				self.selectedTerms[keyString] = { termId: term.termId, studyLevel: term.studyLevel, chapterId: id };
			}

			for (let key in self.item['styleEnum']) {
				let style = self.item['styleEnum'][key];
				self.styles.push({
					key: key,
					value: style
				});
			}

			for (let key in self.item['layoutEnum']) {
				let style = self.item['layoutEnum'][key];
				self.layouts.push({
					key: key,
					value: style
				});
			}
			self.ngxLoader.stop();
		});
		this.apiConnectionService.read('sections/all').then((response) => {
			self.sections = response;
		});
	}

	save() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;

		let postData = {};
		postData = self.item;

		if (this.imageSource != '') {
			postData['imageSource'] = this.imageSource;
		}

		if (this.thumbSource != '') {
			postData['thumbSource'] = this.thumbSource;
		}

		if (this.coverSource != '') {
			postData['coverSource'] = this.coverSource;
		}

		postData['selectedTerms'] = this.selectedTerms;

		this.chaptersService.save('chapters/save', postData).then((response) => {
			self.item = response;
			self.ngxLoader.stop();
			self.successmessage = true;
			self.router.navigate(['/chapter/form/' + self.item['id'] + '']);
		}).catch(function (response) {
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}

	handleCoverChange(e) {
		var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
		var pattern = /image-*/;
		var reader = new FileReader();
		if (!file.type.match(pattern)) {
			alert('invalid format');
			return;
		}
		reader.onload = this._handleCoverReaderLoaded.bind(this);
		reader.readAsDataURL(file);
	}

	_handleCoverReaderLoaded(e) {
		let reader = e.target;
		this.coverSource = reader.result;
	}

	triggerCoverSelect(event) {
		let element: HTMLElement = document.getElementById('cover-select') as HTMLElement;
		element.click();
	}

	handleThumbChange(e) {
		var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
		var pattern = /image-*/;
		var reader = new FileReader();
		if (!file.type.match(pattern)) {
			alert('invalid format');
			return;
		}
		reader.onload = this._handleThumbReaderLoaded.bind(this);
		reader.readAsDataURL(file);
	}

	_handleThumbReaderLoaded(e) {
		let reader = e.target;
		this.thumbSource = reader.result;
	}

	triggerThumbSelect(event) {
		let element: HTMLElement = document.getElementById('thumb-select') as HTMLElement;
		element.click();
	}

	handleImageChange(e) {
		var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
		var pattern = /image-*/;
		var reader = new FileReader();
		if (!file.type.match(pattern)) {
			alert('invalid format');
			return;
		}
		reader.onload = this._handleImageReaderLoaded.bind(this);
		reader.readAsDataURL(file);
	}

	_handleImageReaderLoaded(e) {
		let reader = e.target;
		this.imageSource = reader.result;
	}

	triggerImageSelect(event) {
		let element: HTMLElement = document.getElementById('image-select') as HTMLElement;
		element.click();
	}

	changeTab(target) {
		for (let key in this.tabs) {
			this.tabs[key] = false;
		}
		this.tabs[target] = true;
		sessionStorage.setItem('chapter-tab', target);
	}

	onDrop(event) {
		moveItemInArray(this.item['lessons'], event.previousIndex, event.currentIndex);
		let positions = [];
		for (let key in this.item['lessons']) {
			let lesson = this.item['lessons'][key];
			positions.push({ lessonId: lesson.id, position: key });
		}

		let self = this;
		this.ngxLoader.start();
		this.chaptersService.save('chapterLessons/savePositions', { positions: positions }).then((response) => {
			self.ngxLoader.stop();
		}).catch(function (error) {
			self.ngxLoader.stop();
		});
	}

	isVisible(lesson) {
		let keyword = this.lessonKeyword.toLowerCase();
		let string = lesson.name.toLowerCase();
		try {
			string += lesson['typeText'].toLowerCase();
		} catch (e) {

		}

		if (this.onlyOrphanLessons === true && lesson['chapters'].length > 0) {
			return false;
		}

		if (string.includes(keyword) === false && keyword.length > 2) {
			return false;
		}
		for (let index in this.item['lessons']) {
			let chapterLesson = this.item['lessons'][index];
			if (lesson.id === chapterLesson.lessonId) {
				return false;
			}
		}
		return true;
	}

	addLesson(lesson) {
		let self = this;
		this.ngxLoader.start();
		this.chaptersService.save('chapterLessons/add', { lessonId: lesson.id, chapterId: this.item.id }).then((response) => {
			self.fetchItem();
			self.ngxLoader.stop();
		}).catch(function (error) {
			self.ngxLoader.stop();
		});
	}

	deleteLesson(chapterLesson) {
		let self = this;
		this.ngxLoader.start();
		this.chaptersService.delete('chapterLessons/delete/' + chapterLesson.id + '').then((response) => {
			self.fetchItem();
			self.ngxLoader.stop();
		}).catch(function (error) {
			self.ngxLoader.stop();
		});
	}

	confirmDeleteLesson(chapterLesson) {
		let self = this;

		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je ' + chapterLesson.name + ' wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				this.deleteLesson(chapterLesson);
			}
		});
	}

	termList() {
		let terms = [];
		for (let i = 0; i < this.terms.length; i++) {
			let term = this.terms[i];
			let searchTerm = term.value.toLowerCase();
			let filterTerm = '';
			try {
				filterTerm = term.categories.join(',').toLowerCase();
			} catch (e) {

			}

			let termCategory = this.termCategory.toLowerCase();

			if (this.termCategory.length > 3 && !filterTerm.includes(termCategory)) {
				continue;
			}

			if (this.termKeyword.length < 3) {
				terms.push(term);
			}
			else if (searchTerm.includes(this.termKeyword)) {
				terms.push(term);
			}

		}
		return terms;
	}
}